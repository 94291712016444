<template>
  <div class="page page__content-wrapper">
<!--    <div class="page__label-block">-->
<!--      <button class="btn__label"-->
<!--              v-for="(btn, i) of buttonsLabel"-->
<!--              :key="i"-->
<!--              v-bind:class="[btn.isActive?'active':'']"-->
<!--              @click="changeLabel(i)">-->
<!--        {{ btn.title }}-->
<!--      </button>-->
<!--    </div>-->
    <div class="page__min-container  typography-top">
      <div class="page__img-wrapper">
        <img src="~assets/img/typography-page/top.svg" alt="top img">
      </div>

      <div class="typography-item__download-block">
        <a :href="font.urlZip"
           @click.prevent="downloadItemZip(font)"
           class="btn__circle btn__nav-slider btn-download">
          <img src="~assets/img/icons/icon-zip.svg">
        </a>
      </div>
    </div>
    <div class="page__min-container page__bottom-margin page__text-block">
      <div class="text__normal ">
        Шрифт используется в пяти различных начертаниях и применяется во всех рекламных носителях.
      </div>
    </div>

    <div class="page__full-width page__bottom-margin">
      <TypographyItem v-for="(font, i) of frederickFonts"
                      :key="i"
                      v-bind:index="i"
                      v-bind:Lngth="frederickFonts.length"
                      v-bind:font="font"/>
    </div>


    <div class="page__separate page__bottom-margin"></div>

    <div class="page__min-container page__bottom-margin page__text-block">
      <!--      <div class="text__head">-->
      <!--        Дополнительный шрифт-->
      <!--      </div>-->
      <div class="text__normal" style="margin-bottom: 50px;">
        Резервный шрифт. Применяется для электронных писем, документаций и внутрикорпоративных призентаций.
      </div>
    </div>
    <div class="page__full-width">
      <TypographyItem v-for="(font, i) of openSansFonts"
                      :key="i"
                      v-bind:font="font"/>
    </div>

    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/identity/color">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Цвета
      </router-link>
      <router-link class="next-page__link" to="/identity/picto">
        Пиктограммы
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/identity/picto">
        Пиктограммы
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<script>
import TypographyItem from "@/components/TypografyPage/TypographyItem";
import Axios from "axios";

export default {
  components: {TypographyItem},
  data() {
    return {
      font: {
        urlZip: '../dowload-file/Fonts/Frederik/Frederik.zip',

      },
      buttonsLabel: [
        {title: 'Web', isActive: false},
        {title: 'Desktop', isActive: true},
      ],
      activeFormat: 'Desktop',
      frederickFonts: [
        {
          title: 'formular Light',
          img: '/img/typography-page/formaular-light.svg',
          linkOtf: '#',
          linkTtf: '#',
          urlZip: '../dowload-file/Fonts/Frederik/Frederik.zip'
        },
        {
          title: 'formular Regular',
          img: '/img/typography-page/formular-regular.svg',
          linkOtf: '#',
          linkTtf: '#',
          urlZip: '../dowload-file/Fonts/Frederik/Frederik.zip'
        },
        {
          title: 'formular medium',
          img: '/img/typography-page/formular-medium.svg',
          linkOtf: '#',
          linkTtf: '#',
          urlZip: '../dowload-file/Fonts/Frederik/Frederik.zip'
        },
        {
          title: 'formular Bold',
          img: '/img/typography-page/formular-bold.svg',
          linkOtf: '#',
          linkTtf: '#',
          urlZip: '../dowload-file/Fonts/Frederik/Frederik.zip'
        },
        {
          title: 'formular black',
          img: '/img/typography-page/formular-black.svg',
          linkOtf: '#',
          linkTtf: '#',
          urlZip: '../dowload-file/Fonts/Frederik/Frederik.zip'
        }
      ],
      openSansFonts: [
        {
          title: 'Arial Regular',
          img: '/img/typography-page/arial-regular.svg',
          linkOtf: '#',
          linkTtf: '#',
          linkWoff: '#',
          urlZip: '../dowload-file/Fonts/OpenSans/Open_Sans.zip'
        },
        {
          title: 'arial Bold',
          img: '/img/typography-page/arial-bold.svg',
          linkOtf: '#',
          linkTtf: '#',
          linkWoff: '#',
          urlZip: '../dowload-file/Fonts/OpenSans/Open_Sans.zip'
        }
      ]
    }
  },
  methods: {
    changeLabel(index) {
      this.buttonsLabel.map((item, i) => {
        if (i !== index) {
          return item.isActive = false
        } else {
          this.activeFormat = item.title
          return item.isActive = true
        }
      })
    },
    downloadItemZip({urlZip}) {
      Axios.get(urlZip, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'fonts'
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/var-style";
@import "src/assets/style/text-style";
@import "src/assets/style/page-block";
@import "src/assets/style/buttons";

.page {
  .page__full-width {
    .typography-item {
      .typography-item__top {
        .typography-item__top-title {
          padding: 7.8px 16px;
        }
      }
    }
  }
}


.page__label-block {
  display: none;
}

.typography-top {
  display: flex;
}

.typography-item__download-block {
  margin-left: 20px;

  .btn-download {
    margin-top: 32px;
  }
}

.page__label-block {
  display: block;
  margin-bottom: 94px;
}

.page__content-wrapper {
  margin-bottom: 145px;
}

.btn__label {
  font-weight: 200;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 0.02em;
  padding: 12px 23px;

  &:nth-child(n + 2) {
    margin-left: 33px;
  }
  &:active {
    box-shadow: -3px -3px 8px #FFFFFF, 3px 3px 8px #E4E7EB, inset -4px -3px 4px rgba(255, 255, 255, 0.3), inset 3px 3px 4px rgba(228, 231, 235, 0.3);
  }

  &:hover {

  }

  &.active {
    box-shadow: inset -3px -3px 4px #FFFFFF, inset 3px 3px 4px #E4E7EB;
  }
}

.page__bottom-margin {
  margin-bottom: 49px;
}

.page__separate {
  margin-bottom: 94.4px;
}

.page__img-wrapper {
  flex-shrink: 0;
  margin-bottom: 44px;
}

.page__min-container {
  &:nth-child(4) {
    &.page__bottom-margin {
      margin-bottom: 53px;
    }
  }
}

.page__full-width {
  margin-bottom: 50.7px;
}

@media screen and (max-width: 1456px) {
  .page__content-wrapper {
    margin-bottom: 204px;
  }
  .page__min-container {
    max-width: calc(100% - 17px);
  }
}

@media screen and (max-width: 1370px) {
  .typography-item__download-block {
    margin-top: 20px;
  }
  .typography-item__download-block {
    display: none;
  }
  .bottom-download {
    display: none;
  }
}

@media screen and (max-width: 768px) {

  .page__label-block {
    display: block;
    margin-bottom: 64px;
  }
  .btn__label {
    margin-bottom: 0;
  }
  .page__min-container.page__bottom-margin.page__text-block {
    margin-bottom: 64px;
  }
}
</style>
