<template>
  <div class="typography-item" v-bind:class="{last_item: index === Lngth-1}">
    <div class="typography-item__top">
      <div class="typography-item__top-title">
        {{ font.title }}
      </div>
    </div>
    <div class="typography-item__body">
      <div class="typography-item__font-img">
        <img :src="font.img" :alt="font.title">
      </div>
<!--      <div class="typography-item__download-block">-->
<!--        <a :href="font.urlZip"-->
<!--           @click.prevent="downloadItemZip(font)"-->
<!--           class="btn__circle btn__nav-slider btn-download">-->
<!--          <img src="~assets/img/icons/otf-download.svg"-->
<!--               :alt="`Скачать ${font.title}`">-->
<!--        </a>-->
<!--        <a :href="font.urlZip"-->
<!--           @click.prevent="downloadItemZip(font)"-->
<!--           class="btn__circle btn__nav-slider btn-download">-->
<!--          <img src="~assets/img/icons/ttf-download.svg"-->
<!--               :alt="`Скачать ${font.title}`">-->
<!--        </a>-->
<!--        <a v-if="font.linkWoff"-->
<!--           :href="font.urlZip"-->
<!--           @click.prevent="downloadItemZip(font)" class="btn__circle btn__nav-slider btn-download">-->
<!--          <img src="~assets/img/icons/woff-dowload.svg"-->
<!--               :alt="`Скачать ${font.title}`">-->
<!--        </a>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
import Axios from "axios";

export default {
  props: ['font','Lngth','index'],
  methods: {
    downloadItemZip ({ urlZip, label }) {
      Axios.get(urlZip, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/zip' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/buttons";
.typography-item{
  width: 100%;
  margin-bottom: 47px;
  &__top{
    display: flex;
    margin-bottom: 30.7px;
    &-title{
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.07em;
      font-weight: bold;
      color: #697480;
      padding: 7.8px 16px;
      border-radius: 50px;
      background: #fff;
      text-transform: uppercase;
      font-family: 'formular', sans-serif;
    }
  }
  &__body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__font-img{
    width: 100%;
    max-width: 832px;
  }
  &__download-block{
    display: flex;
  }
}
.last_item{
  margin-bottom: 92px;
}
@media screen and (max-width: 1370px) {
  .typography-item__download-block{
    margin-top: 20px;
  }
  .typography-item__download-block{
    display: none;
  }
  .bottom-download{
    display: none;
  }
}
@media screen and  (max-width: 685px){
  .typography-item__font-img{
    width: 100%;
    margin-bottom: 20px;
    img{
      width: 100%;
    }
  }
  .typography-item__download-block{
    display: none;
  }
}
</style>
